// src/components/Navbar.js
import React from "react";
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <header className="bg-yellow-500 border-gray-200 dark:bg-gray-900 w-full text-black-300 sticky top-0 z-10">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center max-w-screen-xl">
        <Link to="/" className="flex title-font font-medium items-center text-white mb-4 md:mb-0">
          <img
            src="/H.png"
            className="h-8 mr-3"
            alt="harsh-logo"
            height={100}
            width={35}
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            itfeelsharsh
          </span>
        </Link>
        <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
          <Link to="/education" className="mr-5 hover:text-white">Education</Link>
          <Link to="/projects" className="mr-5 hover:text-white">Projects</Link>
          <Link to="/contact" className="mr-5 hover:text-white">Contact Me</Link>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
