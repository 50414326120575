// src/pages/Education.js
import React from "react";
import certificatesData from "../data/education/certificates.json";

const Education = () => {
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
              Education
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              Basic Qualification and Certifications which are completely not fake
            </p>

            <div className="flex mt-4">
              <div className="mr-4">
                <img
                  src="/logos/sec4/1.png"
                  alt="HTML5 Icon"
                  width={48}
                  height={48}
                />
              </div>
              <div className="mr-4">
                <img
                  src="/logos/sec4/2.png"
                  alt="CSS3 Icon"
                  width={48}
                  height={48}
                />
              </div>
              <div className="mr-4">
                <img
                  src="/logos/sec4/3.png"
                  alt="JavaScript Icon"
                  width={48}
                  height={48}
                />
              </div>
              <div>
                <img
                  src="/logos/sec4/4.png"
                  alt="Python Icon"
                  width={48}
                  height={48}
                />
              </div>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img
              src="/banners/education-banner.png"
              height="400"
              width="500"
              alt="mockup"
            />
          </div>
        </div>
      </section>
      <section className="text-gray-800 bg-white body-font">
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-wrap justify-center mb-20">
      <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-800 text-center">
          My Certificates
        </h1>
        <div className="h-1 w-20 bg-indigo-500 rounded mx-auto"></div>
      </div>
    </div>
    <div className="flex flex-wrap -m-4 justify-center">
      {certificatesData.map((certificate) => (
        <div key={certificate.id} className="p-4 xl:w-1/4 md:w-1/2">
          <div className="flex flex-col bg-gradient-to-r from-blue-50 to-indigo-100 p-6 rounded-lg text-center shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl">
            <div className="w-48 h-30 mb-4 mx-auto">
              <img
                src={certificate.image}
                alt={certificate.title}
                width={200}
                height={200}
                className="rounded-lg object-contain"
              />
            </div>
            <h2 className="text-lg text-gray-900 font-bold title-font mb-2">
              {certificate.title}
            </h2>
          </div>
        </div>
      ))}
    </div>
  </div>
</section>


    </>
  );
};

export default Education;
