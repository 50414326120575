import React from "react";

const Contact = () => {
  return (
    <section className="py-20 flex items-center justify-center bg-gradient-to-r from-blue-50 to-white">
      <div className="bg-white shadow-xl rounded-lg p-12 w-full max-w-lg transform transition-transform duration-300 hover:scale-105">
        <h2 className="text-5xl font-extrabold text-center text-gray-800 mb-6">
          Let's Connect
        </h2>
        <p className="text-center text-gray-600 mb-8 text-lg">
          I'm always open to discussing new projects, creative ideas, or
          opportunities to be part of your vision.
        </p>
        <ul className="space-y-6">
          <li className="flex items-center justify-center">
            <a
              href="mail-to:hello@itfeelsharsh.dev"
              className="text-blue-600 hover:underline text-xl flex items-center"
            >
              <span className="mr-2">✉️</span>Email: hello@itfeelsharsh.dev
            </a>
          </li>
          <li className="flex items-center justify-center">
            <a
              href="https://github.com/itfeelsharsh"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline text-xl flex items-center"
            >
              <span className="mr-2">🐱</span>GitHub
            </a>
          </li>
          <li className="flex items-center justify-center">
            <a
              href="https://twitter.com/itfeelsharsh"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline text-xl flex items-center"
            >
              <span className="mr-2">🐦</span>Twitter (X)
            </a>
          </li>
        </ul>
        <p className="text-center text-gray-500 mt-8 text-md">
          I appreciate your interest, and I'm looking forward to hearing from
          you!
        </p>
      </div>
    </section>
  );
};

export default Contact;
