import React from "react";
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
              Hi, I am Harsh Banker
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              I make software stuff which you never gonna understand, and never
              gonna use.
            </p>

            <Link to="/contact" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
              Contact me for project
            </Link>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src="/banners/3.png" height="400" width="450" alt="mockup" loading="lazy" />
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 py-16 mx-auto flex flex-col-reverse md:flex-row items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0 md:mr-8">
            <h2 className="mb-8 text-3xl font-extrabold leading-tight text-center text-gray-900 dark:text-white">
              Data Science, Machine Learning, Deep Learning
            </h2>
            <div className="flex justify-center mt-4">
              <img src="/logos/sec1/1.png" alt="HTML5 Icon" width={48} height={48} loading="lazy" className="mx-2" />
              <img src="/logos/sec1/2.png" alt="CSS3 Icon" width={48} height={48} loading="lazy" className="mx-2" />
              <img src="/logos/sec1/3.png" alt="JavaScript Icon" width={48} height={48} loading="lazy" className="mx-2" />
              <img src="/logos/sec1/4.png" alt="Python Icon" width={48} height={48} loading="lazy" className="mx-2" />
            </div>
            <ul className="list-none pl-4 text-center">
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Developing highly scalable production-ready models for various deep learning and statistical use cases
              </li>
              <br />
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Experience working with Computer Vision and NLP projects
              </li>
              <br />
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Complex quantitative modeling for dynamic forecasting and time series analysis
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/2 flex justify-center">
            <img src="/banners/4.png" alt="Data Science Banner" className="max-w-full" width="400" height="500" loading="lazy" />
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 py-16 mx-auto flex flex-col-reverse md:flex-row items-center">
          <div className="w-full md:w-1/2">
            <img src="/banners/1.png" alt="Web Development Banner" className="max-w-full" width="400" height="500" loading="lazy" />
          </div>
          <div className="w-full md:w-1/2 md:ml-8">
            <h2 className="mb-8 text-3xl font-extrabold leading-tight text-center text-gray-900 dark:text-white">
              Full Stack Web Development
            </h2>
            <div className="flex justify-center mt-4">
              <img src="/logos/sec2/1.png" alt="HTML5 Icon" width={48} height={48} loading="lazy" className="mx-2" />
              <img src="/logos/sec2/2.png" alt="CSS3 Icon" width={48} height={48} loading="lazy" className="mx-2" />
              <img src="/logos/sec2/3.png" alt="JavaScript Icon" width={48} height={48} loading="lazy" className="mx-2" />
              <img src="/logos/sec2/4.png" alt="Python Icon" width={48} height={48} loading="lazy" className="mx-2" />
            </div>
            <ul className="list-none pl-4 text-center">
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Building responsive website front end using React-Redux
              </li>
              <br />
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Developing mobile applications using Flutter, React Native, and solo Android apps using Kotlin
              </li>
              <br />
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Creating application backend in Node, Express & Flask
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 py-16 mx-auto flex flex-col-reverse md:flex-row items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0 md:mr-8">
            <h2 className="mb-8 text-3xl font-extrabold leading-tight text-center text-gray-900 dark:text-white">
              Cloud Infra-Architecture
            </h2>
            <div className="flex justify-center mt-4">
              <img src="/logos/sec3/1.png" alt="Python Icon" width={48} height={48} loading="lazy" className="mx-2" />
              <img src="/logos/sec3/2.png" alt="R Icon" width={48} height={48} loading="lazy" className="mx-2" />
              <img src="/logos/sec3/3.png" alt="TensorFlow Icon" width={48} height={48} loading="lazy" className="mx-2" />
              <img src="/logos/sec3/4.png" alt="PyTorch Icon" width={48} height={48} loading="lazy" className="mx-2" />
            </div>
            <ul className="list-none pl-4 text-center">
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Experience working on multiple cloud platforms
              </li>
              <br />
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases
              </li>
              <br />
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/2 flex justify-center">
            <img src="/banners/2.png" alt="Cloud Infra-Architecture" className="max-w-full" width="400" height="500" loading="lazy" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
